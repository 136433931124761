<script>
//==] IMPORT MODULES [=======================================
import { scale } from 'svelte/transition';
import { getContext } from 'svelte';

let notification_show = getContext('notification_show');
let notification_type = getContext('notification_type');
let notification_text = getContext('notification_text');
//===========================================================

//==] AUTOMATIC TURN OFF NOTIFICATION [======================
function Dismiss() {
	if ( $notification_show == true ) {
		setTimeout( () => {
			$notification_show = false;
		},5000);
	}
}

$: { $notification_show == true; Dismiss(); }
//===========================================================
</script>

{#if $notification_show == true}
	<div in:scale out:scale tabindex="-1" class="card shadow bg-{$notification_type} text-white position-fixed bottom-0 end-0 border-0 opacity-100 m-3 p-3" style="z-index:2000;">
	<div class="card-body">
		<div class="d-flex align-items-center">
		<h5>{@html $notification_text }</h5>
		<button type="button" class="btn-close btn-close-white ms-3 fs-5" on:click='{() => {$notification_show = false;}}'></button>
		</div>
	</div>
	</div>
{/if}
