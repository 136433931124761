<script>
//==] IMPORT MODULES [=======================================
import { getContext, afterUpdate } from 'svelte';
import { fade } from 'svelte/transition';
import SvgIcon from '@jamescoyle/svelte-icon';
import { mdiFileEdit, mdiClose, mdiLoading, mdiContentSave } from '@mdi/js';
import * as bootstrap from 'bootstrap';
import axios from 'axios';
//===========================================================

//==] IMPORT VARIABLES [=====================================
let locked = getContext('locked');
let state = getContext('state');
let notification_type = getContext('notification_type');
let notification_text = getContext('notification_text');
let notification_show = getContext('notification_show');

export let groups;
export let group_edit;
export let Callback = () => {};

let pools = [];
//===========================================================

//==] SET FREE POOLS [=======================================
function Free_pools() {
	let new_pools = ['0','1','2','3','4','5','6','7','8','9'];
	for ( let i=0; i<groups.length; i++ ) {
		for ( let j=0; j<new_pools.length; j++ ) {
			if ( Number(groups[i]['pool']) == Number(new_pools[j]) ) {
				new_pools.splice(j,1);
			}
		}
	}
	new_pools.push(group_edit['pool']);
	new_pools.sort();
	pools = new_pools;
}

afterUpdate( () => {
	Free_pools();
});
//===========================================================

//==] SAVE GROUP DATA [======================================
function Save() {
//--] LOCK INTERFACE [---------------------------------------
$locked = true;
//-----------------------------------------------------------

//--] SAVE DATA AND REFRESH INTERFACE [----------------------
axios.post('assets/backend_groups.php', {
	action: 'groups4',
	id: group_edit['id'],
	name: group_edit['name'],
	pool: group_edit['pool'],
	prefix: group_edit['prefix'],
	number: group_edit['number']
}).then( (response) => {
	if ( response.data.output == "SUCCESS" )
	{
		const modal_hide = document.querySelector('#modal_groups_edit');
		const modal = bootstrap.Modal.getInstance(modal_hide);
		modal.hide();

		$locked = false;
		$notification_type = "success";
		$notification_text = "Dane grupy <strong>"+name+"</strong> zostały zapisane.";
		$notification_show = true;

		group_edit = "";

		Callback(); 
	}
	else {
		$state = "error";
	}
}).catch( (error) => {
	console.log( error );
	$state = "error";
});

//-----------------------------------------------------------
}
//===========================================================
</script>

<div class="modal fade" out:fade id="modal_groups_edit" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
	<div class="modal-dialog modal-md">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title d-flex align-items-center">
					<SvgIcon type="mdi" path="{mdiFileEdit}" size={32} class="me-1"></SvgIcon>
					Edycja grupy:
				</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" title="Zamknij okno" disabled="{$locked}"></button>
			</div>
			<div class="modal-body">
				<label class="form-label" for="name">Nazwa grupy:</label>
				<input class="form-control mb-3" type="text" placeholder="Nazwa grupy" id="name" disabled="{$locked}" bind:value={group_edit['name']}>

				<label class="form-label" for="prefix">Prefiks:</label>
				<input class="form-control mb-3" type="text" placeholder="04701" id="prefix" disabled="{$locked}" bind:value={group_edit['prefix']}>

				<label class="form-label" for="pool">Pula kodów kreskowych:</label>
				<select class="form-select mb-3" id="pool" bind:value={group_edit['pool']} disabled="{$locked || pools.length == 0}">
					{#each pools as p}
						<option value="{p}">{p}</option>
					{/each}
				</select>

				<label class="form-label" for="number">Ostatni numer kodu kreskowego:</label>
				<input class="form-control mb-3" type="number" placeholder="0" id="number" disabled="{$locked}" bind:value={group_edit['number']}>

			</div>
			<div class="modal-footer text-end">
				<button class="btn btn-secondary d-flex align-items-center" type="button" title="Anuluj"
				disabled="{$locked}"
				data-bs-dismiss="modal">
					<SvgIcon type="mdi" path={mdiClose} class="me-1"></SvgIcon>
					Anuluj
				</button>
				<button class="btn btn-primary d-flex align-items-center" type="button" title="Zapisz dane grupy kodów kreskowych"
				disabled="{$locked || group_edit['name'] == '' || pools.length == 0 || group_edit['number'] == ''}"
				on:click={Save}
				>
					{#if $locked == false}
						<SvgIcon type="mdi" path={mdiContentSave} class="me-1"></SvgIcon>
					{/if}
					{#if $locked == true}
						<SvgIcon type="mdi" path={mdiLoading} class="me-1 icon-spinner"></SvgIcon>
					{/if}
					Zapisz
				</button>
			</div>
		</div>
	</div>
</div>
