<script>
//==] IMPORT SVELTE MODULES [================================
import {app_name,app_copyright} from '../variables.js';
//===========================================================
</script>

<svelte:head>
	<meta name="copyright" content="{app_copyright}" />
	<meta name="author" content="Rafał Wojciechowski" />
	<title>{app_name}</title>
</svelte:head>
