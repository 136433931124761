<script>
//==] IMPORT MODULES [=======================================
import { getContext } from 'svelte';
import {location, push} from 'svelte-spa-router';
import SvgIcon from '@jamescoyle/svelte-icon';
import { mdiMenu,mdiThemeLightDark,mdiBrightness4,mdiBrightness7,mdiHome,mdiPower,mdiCog,mdiInformation,mdiFormatListGroup,mdiHistory,mdiLibrary } from '@mdi/js';
import * as bootstrap from 'bootstrap';

import About from './frontend_about.svelte';

let theme = getContext('theme');
let header_text = getContext('header_text');
let notification_show = getContext('notification_show');
let notification_type = getContext('notification_type');
let notification_text = getContext('notification_text');

let locked = getContext('locked');
//===========================================================

//==] SET ACTIVE DROPDOWN BUTTON [===========================
let light_active = "";
let dark_active = "";

if ( $theme == "light" ) {
	light_active = "active";
	dark_active = "";
}

if ( $theme == "dark" ) {
	light_active = "";
	dark_active = "active";
}
//===========================================================

//==] SET DARK/LIGHT MODE [==================================
function Set_theme(mode) {
	$theme = mode;
	document.documentElement.setAttribute('data-bs-theme', $theme);

	const d = new Date();
	d.setTime(d.getTime() + (365*24*60*60*1000));
	let expires = "expires="+ d.toUTCString();
	document.cookie = "theme_mode="+mode+";" + expires + ";path=/";

	if ( mode == "light" ) {
		light_active = "active";
		dark_active = "";

		$notification_type = "success";
		$notification_text = "Wybrano jasny motyw kolorystyczny.";
		$notification_show = true;
	}
	if ( mode == "dark" ) {
		light_active = "";
		dark_active = "active";

		$notification_type = "success";
		$notification_text = "Wybrano ciemny motyw kolorystyczny.";
		$notification_show = true;
	}
}
//===========================================================

//==] SHOW ABOUT DIALOG [====================================
function About_show() {
	const modal = new bootstrap.Modal(document.getElementById("modal_about"), {});
	modal.show();
}
//===========================================================
</script>

<nav class="navbar navbar-expand bg-dark text-white sticky-top shadow">
	<ul class="navbar-nav me-auto ms-2 text-truncate">
		<li class="nav-item">
			<button class="btn btn-secondary" type="button" data-bs-toggle="offcanvas" data-bs-target="#main_menu" aria-controls="main_menu" title="Menu główne" disabled="{$locked}">
			<SvgIcon type="mdi" path={mdiMenu}></SvgIcon>
			</button>
		</li>
		<li class="nav-item">
			<div class="d-flex mx-2" style="height:100%;">
				<div class="vr"></div>
			</div>
		</li>
		<li class="nav-item py-2">
			<div class="fw-bold text-uppercase d-flex align-items-center">

			{#if $location.substring(0,1) == '/'}
				{#if $location.length == 1 }
					<SvgIcon type="mdi" path={mdiHome} class="me-2"></SvgIcon>
				{/if}
			{/if}
			{#if $location == '/groups'}
				<SvgIcon type="mdi" path={mdiFormatListGroup} class="me-2"></SvgIcon>
			{/if}
			{#if $location == '/history'}
				<SvgIcon type="mdi" path={mdiHistory} class="me-2"></SvgIcon>
			{/if}
			{#if $location == '/library'}
				<SvgIcon type="mdi" path={mdiLibrary} class="me-2"></SvgIcon>
			{/if}

			{#if $location == '/settings'}
				<SvgIcon type="mdi" path={mdiCog} class="me-2"></SvgIcon>
			{/if}
			{#if $location == '/logout'}
				<SvgIcon type="mdi" path={mdiPower} class="me-2"></SvgIcon>
			{/if}

			{$header_text}
			</div>
		</li>
	</ul>

	<ul class="navbar-nav ms-auto me-2">
	<li class="nav-item dropdown">
		<button class="btn btn-secondary dropdown-toggle d-flex align-items-center" data-bs-toggle="dropdown" aria-expanded="false" disabled="{$locked}" title="Motyw kolorystyczny">
			<SvgIcon type="mdi" path={mdiThemeLightDark}></SvgIcon>
		</button>
		<ul class="dropdown-menu dropdown-menu-end">
			<li>
				<button class="dropdown-item {light_active}" disabled="{$locked}" on:click={ ()=> {Set_theme('light');} }>
					<SvgIcon type="mdi" path={mdiBrightness7}></SvgIcon> Jasny
				</button>
			</li>
			<li>
				<button class="dropdown-item {dark_active}" disabled="{$locked}" on:click={ ()=> {Set_theme('dark');} }>
					<SvgIcon type="mdi" path={mdiBrightness4}></SvgIcon> Ciemny
				</button>
			</li>
		</ul>
	</li>
	</ul>
</nav>

<div class="offcanvas offcanvas-start" data-bs-theme="dark" tabindex="-1" id="main_menu" aria-labelledby="offcanvasLabel">
	<div class="offcanvas-header">
		<h5 class="offcanvas-title text-uppercase" id="offcanvasLabel">Menu główne</h5>
		<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" disabled="{$locked}"></button>
	</div>
	<div class="offcanvas-body">
		<div class="d-grid gap-2">

			<button class="btn btn-secondary d-flex align-items-center" style="text-align:left;" type="button" title="Strona główna" data-bs-dismiss="offcanvas" disabled="{$locked}" on:click={()=>{push('/')}}>
			<SvgIcon type="mdi" path={mdiHome} class="me-2"></SvgIcon>
			Strona główna
			</button>

			<button class="btn btn-secondary d-flex align-items-center" style="text-align:left;" type="button" title="Historia wydruków" data-bs-dismiss="offcanvas"
			disabled="{$locked}" on:click={()=>{push('/history')}}>
			<SvgIcon type="mdi" path={mdiHistory} class="me-2"></SvgIcon>
			Historia
			</button>

			<button class="btn btn-secondary d-flex align-items-center" style="text-align:left;" type="button" title="Definicje grup i filii"
			data-bs-dismiss="offcanvas" disabled="{$locked}"
			on:click={()=>{push('/groups')}}>
			<SvgIcon type="mdi" path={mdiFormatListGroup} class="me-2"></SvgIcon>
			Grupy i filie
			</button>

			<button class="btn btn-secondary d-flex align-items-center" style="text-align:left;" type="button" title="Ustawienia nagłówka kodu kreskowego"
			data-bs-dismiss="offcanvas" disabled="{$locked}"
			on:click={()=>{push('/library')}}>
			<SvgIcon type="mdi" path={mdiLibrary} class="me-2"></SvgIcon>
			Nagłówek kodu
			</button>

			<button class="btn btn-secondary d-flex align-items-center" style="text-align:left;" type="button" title="Ustawienia aplikacji" data-bs-dismiss="offcanvas" disabled="{$locked}" on:click={()=>{push('/settings')}}>
			<SvgIcon type="mdi" path={mdiCog} class="me-2"></SvgIcon>
			Ustawienia
			</button>

			<hr>

			<button class="btn btn-secondary d-flex align-items-center" style="text-align:left;" type="button" title="Informacje o programie" data-bs-dismiss="offcanvas" disabled="{$locked}"
			on:click={About_show}
			>
			<SvgIcon type="mdi" path={mdiInformation} class="me-2"></SvgIcon>
			O programie
			</button>

		</div>
	</div>

	<hr>

	<button class="btn btn-secondary ms-3 me-3 mb-3 d-flex align-items-center" style="text-align:left;" type="button" title="Wyloguj się" data-bs-dismiss="offcanvas" disabled="{$locked}" on:click={()=>{push('/logout')}}>
		<SvgIcon type="mdi" path={mdiPower} class="me-2"></SvgIcon>
		Wyloguj
	</button>
</div>

<About />

