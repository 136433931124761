<script>
//==] IMPORT MODULES [=======================================
import { getContext } from 'svelte';
import { fade,scale } from 'svelte/transition';
import Router, {location} from 'svelte-spa-router';
import SvgIcon from '@jamescoyle/svelte-icon';
import { mdiLoading, mdiFormatListGroup, mdiPlus, mdiDotsVertical, mdiDelete, mdiFileEdit } from '@mdi/js';
import axios from 'axios';

import Groups_delete from './frontend_groups_delete.svelte';
import Groups_add from './frontend_groups_add.svelte';
import Groups_edit from './frontend_groups_edit.svelte';
//===========================================================

//==] IMPORT STORE VARIABLES [===============================
let locked = getContext('locked');
let header_text = getContext('header_text');
let theme = getContext('theme');
let state = getContext('state');
//===========================================================

//==] DEFINE VARIABLES [=====================================
let groups = [];
let group_id = "";
let group_name = "";
let group_edit = "";
//===========================================================

//==] SET MENU HEADER TEXT AND LOAD DATA [===================
if ( $location == '/groups') {
	$header_text = "Ustawienia grup i filii";
	Groups();
}
//===========================================================

//==] GET DATA [=============================================
function Groups() {
	$locked = true;
	axios.post('assets/backend_groups.php', {
		action: 'groups',
	}).then( (response) => {
		if ( response.data.output == "SUCCESS" )
		{
			$locked = false;
			groups = response.data.groups;
		}
		else {
			$state = "error";
		}
	}).catch( (error) => {
		console.log( error );
		$state = "error";
	});
}
//===========================================================
</script>

{#if $location == "/groups"}
	<div class="container mt-3">
		{#if $locked == true}
			<div in:fade class="alert alert-info">
				<SvgIcon type="mdi" path="{mdiLoading}" class="icon-spinner"></SvgIcon> Proszę czekać, trwa ładowanie danych...
			</div>
		{/if}
		{#if $locked == false}
			<div class="card shadow">
				<div in:fade class="card-header">
					<div class="row align-items-center">
						<div class="col d-flex align-items-center">
							<SvgIcon type="mdi" path="{mdiFormatListGroup}" size="{32}" class="me-1"></SvgIcon>
							<div class="fs-5">Grupy i filie:</div>
						</div>
						<div class="col-auto">
							<button class="btn btn-lg btn-{$theme} d-flex align-items-center" type="button"
							data-bs-toggle="modal"
							data-bs-target="#modal_groups_add"
							title="Dodaj grupę">
								<SvgIcon type="mdi" path="{mdiPlus}"></SvgIcon>
							</button>
						</div>
					</div>
				</div>

				{#if groups.length == 0}
					<div class="card-body">
						Brak zdefiniowanych grup w bazie danych...
					</div>
				{:else}
					<ul class="list-group list-group-flush">
						{#each groups as group, i}
							<li class="list-group-item list-group-item-action">
								<div class="row">
									<div class="col-auto">{(i+1)}.</div>
									<div class="col text-truncate">
										<strong>{group['name']}</strong>
										<br>
										<span class="text-muted me-3">Numer puli: {group['pool']},</span>
										<span class="text-muted me-3">Ostatni numer kodu: {group['number']}</span>
									</div>
									<div class="col-auto d-flex align-items-center">
										<div in:fade class="dropdown m-auto">
											<button class="btn btn-lg btn-{$theme} d-flex align-items-center p-1" type="button" data-bs-toggle="dropdown" title="Akcja"
											id="dropdown_groups"
											>
											<SvgIcon type="mdi" path={mdiDotsVertical}></SvgIcon>
											</button>
											<ul class="dropdown-menu dropdown-menu-end shadow" aria-labelledby="dropdown_groups">
												<li>
													<button class="dropdown-item d-flex align-item-center" type="button"
													data-bs-toggle="modal"
													data-bs-target="#modal_groups_edit"
													on:click={()=>{group_edit=group}}
													title="Edytuj grupę">
													<SvgIcon type="mdi" path="{mdiFileEdit}" class="me-1"></SvgIcon> Edytuj
													</button>
												</li>

												<li>
													<button class="dropdown-item d-flex align-item-center" type="button"
													data-bs-toggle="modal"
													data-bs-target="#modal_groups_delete"
													on:click={()=>{group_id=group['id']; group_name=group['name']}}
													title="Usuń grupę">
													<SvgIcon type="mdi" path="{mdiDelete}" class="me-1"></SvgIcon> Usuń
													</button>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</li>
						{/each}
					</ul>
				{/if}
			</div>
		{/if}
	</div>
	<Groups_add bind:groups={groups} Callback={Groups} />
	<Groups_delete bind:group_name={group_name} bind:group_id={group_id} Callback={Groups} />
	<Groups_edit bind:groups={groups} bind:group_edit={group_edit} Callback={Groups} />
{/if}
