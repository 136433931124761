<script>
//==] IMPORT SVELTE MODULES [================================
import Router, {location, link, push, pop} from 'svelte-spa-router';
import {wrap} from 'svelte-spa-router/wrap';
import {setContext,getContext} from 'svelte';
import { writable } from 'svelte/store';

import axios from 'axios';
import * as bootstrap from 'bootstrap';
//===========================================================

//==] IMPORT MAIN APP MODULES [==============================
import Head from "./lib/frontend_head.svelte";
import Home from "./lib/frontend_home.svelte";
import Footer from "./lib/frontend_footer.svelte";
import Menu from "./lib/frontend_menu.svelte";
import Loading from "./lib/frontend_loading.svelte";
import Login from "./lib/frontend_login.svelte";
import Logout from "./lib/frontend_logout.svelte";
import Error_500 from "./lib/frontend_error_500.svelte";
import Error_404 from "./lib/frontend_error_404.svelte";
import Settings from "./lib/frontend_settings.svelte";
import Notifications from "./lib/frontend_notifications.svelte";

import Groups from "./lib/frontend_groups.svelte";
import History from "./lib/frontend_history.svelte";
import History_group from "./lib/frontend_history_group.svelte";
import Library from "./lib/frontend_library.svelte";
//===========================================================

//==] SETUP STORE VARIABLES [================================
let state = writable("loading");
setContext("state", state);

let theme = writable("light");
setContext("theme", theme);

let header_text = writable("");
setContext("header_text", header_text);

let notification_show = writable(false);
setContext("notification_show", notification_show);

let notification_type = writable("alert");
setContext("notification_type", notification_type);

let notification_text = writable("");
setContext("notification_text", notification_text);

let locked = writable(false);
setContext("locked", locked);
//===========================================================

//==] SETUP ROUTES [=========================================
let routes = {
	'/': Home,
	'/settings': Settings,
	'/logout': Logout,
	'/groups': Groups,
	'/history': History,
	'/history/:id': History_group,
	'/library': Library,
	'/error_404': Error_404,
	'*': Error_404,
}
//===========================================================

//==] GET THEME FROM COOKIE [================================
function getCookie(cname) {
	let name = cname + "=";
	let ca = document.cookie.split(';');
	for(let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

if( getCookie('theme_mode') == 'light' || getCookie('theme_mode') == '' ) {
	$theme = 'light';
	document.documentElement.setAttribute('data-bs-theme', 'light');
}

if( getCookie('theme_mode') == 'dark' ) {
	$theme = 'dark';
	document.documentElement.setAttribute('data-bs-theme', 'dark');
}
//===========================================================

//==] CHECK APPLICATION STATE [==============================
function Check_state() {
	axios.post('assets/backend_restore.php', {
		action: 'restore'
	}).then( (response) => {
			if ( response.data.output == "SUCCESS" )
			{
				Check_login();
			}
			else {
				$state = "error";
			}
	}).catch( (error) => {
		console.log( error );
		$state = "error";
	});
}
//===========================================================

//==] CHECK IF USER IS LOGGED IN [===========================
function Check_login() {
	axios.post('assets/backend_login.php', {
		action: 'login'
	}).then( (response) => {
			if ( response.data.output == "LOGGED_IN" )
			{
				$state = "logged_in";
			}
			else if ( response.data.output == "NOT_LOGGED_IN" )
			{
				$state = "login";
			}
			else {
				$state = "error";
			}

	}).catch( (error) => {
		console.log( error );
		$state = "error";
	});

}
//===========================================================

//==] START APPLICATION [====================================
Check_state();
//===========================================================
</script>

<Head />
<main class="mb-5">
	{#if $state == "logged_in"}
		<Menu />
		<Router routes={routes} />
		<Notifications />
	{/if}
	{#if $state == "loading"}
		<Loading />
	{/if}
	{#if $state == "login"}
		<Login />
	{/if}
	{#if $state == "error"}
		<Error_500 />
	{/if}

</main>
<br>
<Footer />

