<script>
//==] IMPORT MODULES [=======================================
import { getContext } from 'svelte';
import { fade,scale } from 'svelte/transition';
import Router, {location} from 'svelte-spa-router';
import SvgIcon from '@jamescoyle/svelte-icon';
import { mdiLoading, mdiLibrary, mdiContentSave } from '@mdi/js';
import axios from 'axios';
//===========================================================

//==] IMPORT STORE VARIABLES [===============================
let locked = getContext('locked');
let header_text = getContext('header_text');
let state = getContext('state');
let notification_type = getContext('notification_type');
let notification_text = getContext('notification_text');
let notification_show = getContext('notification_show');
//===========================================================

//==] DEFINE VARIABLES [=====================================
let library = [];
//===========================================================

//==] SET MENU HEADER TEXT AND LOAD DATA [===================
if ( $location == '/library') {
	$header_text = "Ustawienia nagłówka kodu kreskowego";
	Library();
}
//===========================================================

//==] GET LIBRARY DATA [=====================================
function Library() {
	$locked = true;
	axios.post('assets/backend_library.php', {
		action: 'library',
	}).then( (response) => {
		if ( response.data.output == "SUCCESS" )
		{
			$locked = false;
			library = response.data.library;
		}
		else {
			$state = "error";
		}
	}).catch( (error) => {
		console.log( error );
		$state = "error";
	});
}

//===========================================================

//==] SAVE LIBRARY DATA [====================================
function Library2() {
//--] LOCK INTERFACE [---------------------------------------
$locked = true;
//-----------------------------------------------------------

//--] SAVE LIBRARY DATA [------------------------------------
axios.post('assets/backend_library.php', {
	action: 'library2',
	name: library['name'],
	font_size: library['font_size']
}).then( (response) => {
	if ( response.data.output == "SUCCESS" )
	{
		$locked = false;
		$notification_type = "success";
		$notification_text = "Dane nagłówka kodu kreskowego zostały zapisane.";
		$notification_show = true;
	}
	else {
		$state = "error";
	}
}).catch( (error) => {
	console.log( error );
	$state = "error";
});
//-----------------------------------------------------------
}
//===========================================================
</script>
{#if $location == "/library"}
	<div in:fade class="container mt-3">
		<div class="card">
			<div class="card-body">
				<form on:submit|preventDefault={Library2}>
					<label class="form-label" for="name">Treść nagłówka kodu kreskowego:</label>
					<input class="form-control mb-3" type="text" id="name" 
					disabled="{$locked}"
					bind:value={library['name']}>

					<label class="form-label" for="font_size">Wielkość czcionki w pt:</label>
					<input class="form-control mb-3" type="number" id="font_size"
					disabled="{$locked}"
					bind:value={library['font_size']}>
					<hr>
					<button type="submit" class="btn btn-primary float-end" title="Zapisz dane"
					disabled="{$locked || library['name'] == '' || library['font_size'] == ''}"
					on:submit|preventDefault={Library2}>
					{#if $locked == true}
						<SvgIcon type="mdi" path="{mdiLoading}" class="icon-spinner"></SvgIcon>
					{:else}
						<SvgIcon type="mdi" path="{mdiContentSave}"></SvgIcon>
					{/if}
					Zapisz
					</button>
				</form>
			</div>
		</div>
	</div>
{/if}
