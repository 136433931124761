<script>
//==] IMPORT MODULES [=======================================
import { getContext } from 'svelte';
import { fade,scale } from 'svelte/transition';
import Router, {location} from 'svelte-spa-router';
import SvgIcon from '@jamescoyle/svelte-icon';
import { mdiLoading,mdiDatabase,mdiBookInformationVariant,mdiLock,mdiContentSave,mdiDotsVertical,mdiCog,mdiDeleteCircle,mdiChevronRightCircle,mdiChevronLeftCircle,mdiPlusCircle,mdiDownloadCircle,mdiRestore } from '@mdi/js';
import axios from 'axios';

import Settings_logs_delete from './frontend_settings_logs_delete.svelte';
import Settings_logs from './frontend_settings_logs.svelte';
import Settings_backup_new from './frontend_settings_backup_new.svelte';
import Settings_backup_delete from './frontend_settings_backup_delete.svelte';
import Settings_backup_restore from './frontend_settings_backup_restore.svelte';
import Settings_backup_delete_all from './frontend_settings_backup_delete_all.svelte';

let state = getContext('state');
let theme = getContext('theme');
let header_text = getContext('header_text');
let notification_show = getContext('notification_show');
let notification_type = getContext('notification_type');
let notification_text = getContext('notification_text');
let locked = getContext('locked');

let rotate_logs = "1000";
let backups = [];
let logs_all = [];
let logs = [];

let tab = "logs";

// Pagination
let logs_pagination = 50;
let logs_page = 0;
let logs_max = 0;

let form_password_disabled = false;
let button_password_disabled = true;
let button_password_loading = false;
let old_password = "";
let new_password1 = "";
let new_password2 = "";

let backup_file = "";
//===========================================================

//==] SET MENU HEADER TEXT AND LOAD DATA [===================
if ( $location == '/settings') {
	$header_text = "Ustawienia";
	Settings();
}
//===========================================================

//==] GET DATA [=============================================
function Settings() {
	axios.post('assets/backend_settings.php', {
		action: 'settings',
	}).then( (response) => {
		if ( response.data.output == "SUCCESS" )
		{
			$locked = false;
			rotate_logs = response.data.rotate_logs.toString();
			backups = response.data.backups;
			logs_all = response.data.logs;
			Paginate_logs(0);
		}
		else {
			$state = "error";
		}
	}).catch( (error) => {
		console.log( error );
		$state = "error";
	});
}

$: { $location; Settings(); }
//===========================================================

//==] VALIDATE PASSWORD FORM [===============================
function Settings2() {
	if ( old_password != "" && new_password1 != "" && new_password2 != "" ) {
		if ( new_password1 != new_password2 )
		{
			$notification_type = "danger";
			$notification_text = "<strong>Błąd!</strong> Hasło i potwierdzenie nie zgadzają się.";
			$notification_show = true;
			button_password_disabled = true;
		}
		else {
		// Add minimum password length check here.
			if ( new_password1.length > 5 && new_password2.length > 5 ) {
				$notification_show = false;
				form_password_disabled = false;
				button_password_disabled = false;
			}
			else {
				$notification_type = "danger";
				$notification_text = "<strong>Błąd!</strong> Nowe hasło musi mieć minimum <strong>6</strong> znaków.";
				$notification_show = true;
				button_password_disabled = true;
			}
		}
	}
	else {
		form_password_disabled = false;
		button_password_disabled = true;
	}
}
//===========================================================

//==] CHANGE PASSWORD [======================================
function Settings3() {
//--] LOCK INTERFACE [---------------------------------------
$notification_show = false;
form_password_disabled = true;
button_password_disabled = true;
button_password_loading = true;
//-----------------------------------------------------------

//--] CHANGE PASSWORD OR THROW ERROR [-----------------------
axios.post('assets/backend_settings.php', {
	action: 'settings2',
	old_password: old_password,
	new_password: new_password1
}).then( (response) => {
	if ( response.data.output == "SUCCESS" )
	{
		form_password_disabled = false;
		button_password_loading = false;
		old_password = "";
		new_password1 = "";
		new_password2 = "";

		$notification_type = "success";
		$notification_text = "Hasło aplikacji zostało zmienione.";
		$notification_show = true;
		Settings();
	}
	else {
		form_password_disabled = false;
		button_password_loading = false;
		old_password = "";
		new_password1 = "";
		new_password2 = "";

		$notification_type = "danger";
		$notification_text = "<strong>Błąd!</strong> Stare hasło jest niepoprawne.";
		$notification_show = true;
	}
}).catch( (error) => {
	console.log( error );
	$state = "error";
});
//-----------------------------------------------------------
}
//===========================================================

//==] PAGINATE LOGS [========================================
function Paginate_logs(page) {
	logs = [];
	let logs_temp = [];
	logs_page = page;
	logs_max = 0;

	for ( let i=0; i<logs_all.length; i++ ) {
		if ( logs_all[i] ) {
			logs_max = Number(logs_max) + 1;
			logs_temp.push( logs_all[i] );
		}
	}

	// Prepare data from temporary array
	for ( let i=Number(page); i<( Number(logs_pagination) + Number(page) ); i++)
	{
		if ( logs_temp[i] ) {
			logs.push( logs_temp[i] );
		}
	}

}
//===========================================================

//==] PAGINATION - NEXT PAGE [===========================
function Settings4() {
	logs_page = ( Number(logs_page) + Number(logs_pagination) );
	Paginate_logs(logs_page);
}
//=======================================================

//==] PAGINATION - PREVIOUS PAGE [=======================
function Settings5() {
	logs_page = ( Number(logs_page) - Number(logs_pagination) );
	Paginate_logs(logs_page);
}
//=======================================================
</script>

{#if $location == "/settings"}
<div class="container mt-3">
{#if $locked == true}
	<div in:fade class="alert alert-info">
	<SvgIcon type="mdi" path="{mdiLoading}" class="icon-spinner"></SvgIcon> Proszę czekać, trwa ładowanie danych...
	</div>
{/if}
{#if $locked == false}
	<div class="card shadow">
		<div in:fade class="card-header">
			<div class="row align-items-center">
				<div class="col">
					<ul class="nav nav-tabs card-header-tabs">
						<li class="nav-item">
							<button class="{tab == 'logs' ? 'nav-link active' : 'btn nav-link'}" type="button" title="Logi aplikacji"
							on:click='{() => { tab = "logs" }}'
							>
							<SvgIcon type="mdi" path="{mdiBookInformationVariant}"></SvgIcon>
							Logi
							</button>
						</li>
						<li class="nav-item">
							<button class="{tab == 'backups' ? 'nav-link active' : 'btn nav-link'}" type="button" title="Kopie zapasowe bazy danych"
							on:click='{() => { tab = "backups" }}'
							>
							<SvgIcon type="mdi" path="{mdiDatabase}"></SvgIcon>
							Kopie zapasowe
							</button>
						</li>
						<li class="nav-item">
							<button class="{tab == 'password' ? 'nav-link active' : 'btn nav-link'}" type="button" title="Zmiana hasła logowania"
							on:click='{() => { tab = "password" }}'
							>
							<SvgIcon type="mdi" path="{mdiLock}"></SvgIcon>
							Zmiana hasła
							</button>
						</li>

					</ul>
				</div>
				<div class="col-auto">
				{#if tab == "logs"}
					<div in:fade class="dropdown m-auto">
						<button class="btn btn-lg btn-{$theme} d-flex align-items-center p-1" type="button" data-bs-toggle="dropdown" title="Akcja" id="dropdown_logs"
						>
						<SvgIcon type="mdi" path={mdiDotsVertical}></SvgIcon>
						</button>
						<ul class="dropdown-menu dropdown-menu-end shadow" aria-labelledby="dropdown_logs">
							<li>
								<button class="dropdown-item d-flex align-item-center" type="button"
								data-bs-toggle="modal"
								data-bs-target="#modal_logs_settings"
								title="Ustawienia logów">
								<SvgIcon type="mdi" path="{mdiCog}" class="me-1"></SvgIcon> Ustawienia logów
								</button>
							</li>
							<li>
								<button class="dropdown-item d-flex align-item-center" type="button"
								data-bs-toggle="modal"
								data-bs-target="#modal_logs_delete"
								title="Skasuj logi aplikacji">
								<SvgIcon type="mdi" path="{mdiDeleteCircle}" class="me-1"></SvgIcon> Kasuj logi
								</button>
							</li>

						</ul>
					</div>
				{/if}
				{#if tab == "backups"}
					<div in:fade class="dropdown m-auto">
						<button class="btn btn-lg btn-{$theme} d-flex align-items-center p-1" type="button" data-bs-toggle="dropdown" title="Akcja" id="dropdown_backups"
						>
						<SvgIcon type="mdi" path={mdiDotsVertical}></SvgIcon>
						</button>
						<ul class="dropdown-menu dropdown-menu-end shadow" aria-labelledby="dropdown_backups">
							<li>
								<button class="dropdown-item d-flex align-item-center" type="button"
								data-bs-toggle="modal"
								data-bs-target="#modal_backup_new"
								title="Nowa kopia bazy danych">
								<SvgIcon type="mdi" path="{mdiPlusCircle}" class="me-1"></SvgIcon> Nowa kopia
								</button>
							</li>
							<li>
								<button class="dropdown-item d-flex align-item-center" type="button"
								data-bs-toggle="modal"
								data-bs-target="#modal_backup_delete_all"
								title="Usuń wszystkie kopie bazy danych">
								<SvgIcon type="mdi" path="{mdiDeleteCircle}" class="me-1"></SvgIcon> Usuń wszystkie kopie
								</button>
							</li>

						</ul>
					</div>
				{/if}

				</div>
			</div>
		</div>
		{#if tab == 'logs'}
			{#if logs.length == 0}
				<div class="card-body">
					<p>Brak zarejestrowanej aktywności...</p>
				</div>
			{:else}
				<ul class="list-group list-group-flush">
				{#each logs as log, i}
					<li class="list-group-item list-group-item-action">
						<div class="row">
							<div class="col-auto">{(i+1+Number(logs_page))}.</div>
							<div class="col-auto">
								<strong>{log.event_date}</strong>
							</div>
							<div class="col">
								{@html log.event_message}
							</div>
						</div>
					</li>
				{/each}
				</ul>
			{/if}
		{/if}

		{#if tab == 'backups'}
			{#if backups.length  == 0}
				<div class="card-body">
					Brak kopii zapasowych bazy danych...
				</div>
			{:else}
				<ul class="list-group list-group-flush">
					{#each backups as backup, i}
						<li class="list-group-item list-group-item-action">
							<div class="row">
								<div class="col-auto my-auto">{(i+1)}.</div>
								<div class="col my-auto text-truncate">
									<strong>{backup.file}</strong>
								</div>
								<div class="col-auto my-auto">
									{backup.size}
								</div>
								<div class="col-auto d-flex">
									<div class="vr mx-2 h100"></div>
									<div in:fade class="dropdown m-auto">
										<button class="btn btn-lg btn-{$theme} d-flex align-items-center p-1" type="button" data-bs-toggle="dropdown" title="Zarządzanie kopią zapasową" id="dropdown_backups"
										>
											<SvgIcon type="mdi" path={mdiDotsVertical}></SvgIcon>
										</button>
										<ul class="dropdown-menu dropdown-menu-end shadow" aria-labelledby="dropdown_backups">
											<li>
												<a class="dropdown-item d-flex-align-items-center" href="assets/backup/{backup.file}" download title="Pobierz plik kopii zapasowej">
													<SvgIcon type="mdi" path="{mdiDownloadCircle}" class="me-1"></SvgIcon> Pobierz
												</a>
											</li>
											<li>
												<button class="dropdown-item d-flex align-item-center" type="button"
												data-bs-toggle="modal"
												data-bs-target="#modal_backup_restore"
												on:click={()=>{backup_file=backup.file}}
												title="Przywróć bazę danych z tej kopii zapasowej">
													<SvgIcon type="mdi" path="{mdiRestore}" class="me-1"></SvgIcon> Przywróć
												</button>
											</li>
											<li><hr class="dropdown-divider"></li>
											<li>
												<button class="dropdown-item d-flex align-item-center" type="button"
												data-bs-toggle="modal"
												data-bs-target="#modal_backup_delete"
												on:click={()=>{backup_file=backup.file}}
												title="Usuń kopię bazy dannych">
													<SvgIcon type="mdi" path="{mdiDeleteCircle}" class="me-1"></SvgIcon> Usuń
												</button>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</li>
					{/each}
				</ul>
			{/if}
		{/if}
		{#if tab == 'password'}
			<div class="card-body">
				<form on:submit|preventDefault={Settings3}>
					<div class="row align-items-center">
						<div class="col-sm-12 col-md-3 p-1">
							Stare hasło:
						</div>
						<div class="col-sm-12 col-md-9 p-1">
							<input type="password" class="form-control" required disabled="{form_password_disabled}" bind:value={old_password} on:input={Settings2}>
						</div>
						<div class="col-sm-12 col-md-3 p-1">
							Nowe hasło:
						</div>
						<div class="col-sm-12 col-md-9 p-1">
							<input type="password" class="form-control" required disabled="{form_password_disabled}" bind:value={new_password1} on:input={Settings2}>
						</div>
						<div class="col-sm-12 col-md-3 p-1">
							Potwierdzenie hasła:
						</div>
						<div class="col-sm-12 col-md-9 p-1">
							<input type="password" class="form-control" required disabled="{form_password_disabled}" bind:value={new_password2} on:input={Settings2}>
						</div>
						<div class="col-12 p-1 text-end">
							<button type="submit" class="btn btn-primary" title="Zmień hasło" disabled="{button_password_disabled}" on:submit|preventDefault={Settings3}>
							{#if button_password_loading == true}
								<SvgIcon type="mdi" path="{mdiLoading}" class="icon-spinner"></SvgIcon>
							{:else}
								<SvgIcon type="mdi" path="{mdiContentSave}"></SvgIcon>
							{/if}
								Zapisz
							</button>
						</div>
					</div>
				</form>
			</div>
		{/if}

		{#if tab == "logs"}
			{#if ( Number(logs_pagination) ) < Number(logs_max) }
				<div class="card-footer">
					<div class="row justify-content-center">
						<div class="col text-end">
							{#if Number(logs_page) > 0}
								<button in:scale out:scale class="btn btn-secondary align-items-center" title="Poprzednia strona" on:click='{Settings5}'>
									<SvgIcon type="mdi" path="{mdiChevronLeftCircle}"></SvgIcon>
								</button>
							{/if}
						</div>
						<div class="col">
							{#if ( Number(logs_page) + Number(logs_pagination) ) < Number(logs_max) }
								<button in:scale out:scale class="btn btn-secondary align-items-center" title="Następna strona" on:click='{Settings4}'>
									<SvgIcon type="mdi" path="{mdiChevronRightCircle}"></SvgIcon>
								</button>
							{/if}
						</div>
					</div>
				</div>
			{/if}
		{/if}

	</div>
{/if}

<Settings_logs_delete Settings_callback={ ()=> Settings() } />
<Settings_logs Settings_callback={ ()=> Settings() } bind:rotate_logs={ rotate_logs } />
<Settings_backup_new Settings_callback={ ()=> Settings() } />
<Settings_backup_delete Settings_callback={ ()=> Settings() } bind:backup_file={backup_file} />
<Settings_backup_restore Settings_callback={ ()=> Settings() } bind:backup_file={backup_file} />
<Settings_backup_delete_all Settings_callback={ ()=> Settings() } />

</div>
{/if}

