<script>
//==] IMPORT MODULES [=======================================
import SvgIcon from '@jamescoyle/svelte-icon';
import { mdiAlertCircle } from '@mdi/js';
//===========================================================
</script>

<div class="container mt-3">
	<div class="alert alert-danger m-4">
		<h3>500</h3>
		<SvgIcon type="mdi" path={mdiAlertCircle}></SvgIcon> Błąd serwera...
	</div>
</div>
