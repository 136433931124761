<script>
//==] IMPORT MODULES [=======================================
import { getContext, afterUpdate } from 'svelte';
import { fade } from 'svelte/transition';
import SvgIcon from '@jamescoyle/svelte-icon';
import { mdiPlus, mdiClose, mdiLoading } from '@mdi/js';
import * as bootstrap from 'bootstrap';
import axios from 'axios';
//===========================================================

//==] IMPORT VARIABLES [=====================================
let locked = getContext('locked');
let state = getContext('state');
let notification_type = getContext('notification_type');
let notification_text = getContext('notification_text');
let notification_show = getContext('notification_show');

export let groups;
export let Callback = () => {};

let pools = [];
let pool = "";
let name = "";
let prefix = "";
//===========================================================

//==] SET FREE POOLS [=======================================
function Free_pools() {
	let new_pools = ['0','1','2','3','4','5','6','7','8','9'];
	for ( let i=0; i<groups.length; i++ ) {
		for ( let j=0; j<new_pools.length; j++ ) {
			if ( Number(groups[i]['pool']) == Number(new_pools[j]) ) {
				new_pools.splice(j,1);
			}
		}
	}
	pools = new_pools;
	pool = pools[0];
}

afterUpdate( () => {
	Free_pools();
});
//===========================================================

//==] ADD GROUP [============================================
function Add_group() {
//--] LOCK INTERFACE [---------------------------------------
$locked = true;
//-----------------------------------------------------------

//--] ADD GROUP AND REFRESH INTERFACE [----------------------
axios.post('assets/backend_groups.php', {
	action: 'groups3',
	name: name,
	pool: pool,
	prefix: prefix
}).then( (response) => {
	if ( response.data.output == "SUCCESS" )
	{
		const modal_hide = document.querySelector('#modal_groups_add');
		const modal = bootstrap.Modal.getInstance(modal_hide);
		modal.hide();

		$locked = false;
		$notification_type = "success";
		$notification_text = "Grupa <strong>"+name+"</strong> została dodana.";
		$notification_show = true;

		pool = "";
		name = "";
		prefix = "";

		Callback(); 
	}
	else {
		$state = "error";
	}
}).catch( (error) => {
	console.log( error );
	$state = "error";
});
//-----------------------------------------------------------
}
//===========================================================
</script>

<div class="modal fade" out:fade id="modal_groups_add" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
	<div class="modal-dialog modal-md">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title d-flex align-items-center">
					<SvgIcon type="mdi" path="{mdiPlus}" size={32} class="me-1"></SvgIcon>
					Dodawanie grupy:
				</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" title="Zamknij okno" disabled="{$locked}"></button>
			</div>
			<div class="modal-body">
				<label class="form-label" for="name">Nazwa grupy:</label>
				<input class="form-control mb-3" type="text" placeholder="Nazwa grupy" id="name" disabled="{$locked}" bind:value={name}>

				<label class="form-label" for="prefix">Prefiks:</label>
				<input class="form-control mb-3" type="text" placeholder="04701" id="prefix" disabled="{$locked}" bind:value={prefix}>

				<label class="form-label" for="pool">Pula kodów kreskowych:</label>
				<select class="form-select mb-3" id="pool" bind:value={pool} disabled="{$locked || pools.length == 0}">
					{#each pools as p}
						<option value="{p}">{p}</option>
					{/each}
				</select>
			</div>
			<div class="modal-footer text-end">
				<button class="btn btn-secondary d-flex align-items-center" type="button" title="Anuluj"
				disabled="{$locked}"
				data-bs-dismiss="modal">
					<SvgIcon type="mdi" path={mdiClose} class="me-1"></SvgIcon>
					Anuluj
				</button>
				<button class="btn btn-primary d-flex align-items-center" type="button" title="Dodaj grupę kodów kreskowych"
				disabled="{$locked || name == "" || pools.length == 0}"
				on:click={Add_group}
				>
					{#if $locked == false}
						<SvgIcon type="mdi" path={mdiPlus} class="me-1"></SvgIcon>
					{/if}
					{#if $locked == true}
						<SvgIcon type="mdi" path={mdiLoading} class="me-1 icon-spinner"></SvgIcon>
					{/if}
					Dodaj
				</button>
			</div>
		</div>
	</div>
</div>
