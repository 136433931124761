<script>
//==] IMPORT SVELTE MODULES [================================
import {getContext} from 'svelte';
import { scale } from 'svelte/transition';
import SvgIcon from '@jamescoyle/svelte-icon';
import { mdiChevronUpCircle } from '@mdi/js';

import {app_name,app_version,app_copyright} from '../variables.js';
let state = getContext('state');
//===========================================================
</script>

<footer class="fixed-bottom bg-dark text-white fs-6 shadow">
	<div class="row align-items-center">
		<div class="col-auto"></div>
		<div class="col text-center p-2 text-truncate">
			<strong>{app_name}</strong> v{app_version} Copyright &copy; 2017 - 2024 Rafał Wojciechowski. Licencja dla: {app_copyright}.
		</div>
		<div class="col-auto">
			{#if $state == "logged_in"}
				<button in:scale type="button" class="btn btn-secondary m-2" title="Przewiń w górę" on:click={()=>{ window.scrollTo({ top: 0, behavior: 'smooth' }) }}>
					<SvgIcon type="mdi" path="{mdiChevronUpCircle}"></SvgIcon>
				</button>
			{/if}
		</div>
	</div>
</footer>
