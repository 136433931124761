<script>
//==] IMPORT MODULES [=======================================
import { getContext } from 'svelte';
import { fade,scale } from 'svelte/transition';
import Router, {location} from 'svelte-spa-router';
import SvgIcon from '@jamescoyle/svelte-icon';
import { mdiLoading, mdiBarcode, mdiHistory, mdiDotsVertical, mdiDelete, mdiChevronLeftCircle, mdiChevronRightCircle } from '@mdi/js';
import axios from 'axios';

import History_delete from './frontend_history_delete.svelte';
import History_delete_all from './frontend_history_delete_all.svelte';
import History_generate from './frontend_history_generate.svelte';
//===========================================================

//==] IMPORT STORE VARIABLES [===============================
let state = getContext('state');
let locked = getContext('locked');
let header_text = getContext('header_text');
let theme = getContext('theme');
//===========================================================

//==] DEFINE LOCAL VARIABLES [===============================
let history_all = [];
let history = [];
let groups = [];
let id = "";
let group_name = "";
let history_gen = "";
let library_data = "";

// Pagination
let history_pagination = 30;
let history_page = 0;
let history_max = 0;
//===========================================================

//==] SET MENU HEADER TEXT AND LOAD DATA [===================
if ( $location == '/history') {
	$header_text = "Historia wydruków";
	History();
}
//===========================================================

//==] GET DATA [=============================================
function History() {
	$locked = true;

	axios.post('assets/backend_history.php', {
		action: 'history',
	}).then( (response) => {
		if ( response.data.output == "SUCCESS" )
		{
			$locked = false;
			history_all = response.data.history;
			library_data = response.data.library;
			Paginate(0);
		}
		else {
			$state = "error";
		}
	}).catch( (error) => {
		console.log( error );
		$state = "error";
	});
}

$: { $location; History(); }
//===========================================================

//==] PAGINATE DATA [========================================
function Paginate(page) {
	history = [];
	let history_temp = [];
	history_page = page;
	history_max = 0;

	for ( let i=0; i<history_all.length; i++ ) {
		if ( history_all[i] ) {
			history_max = Number(history_max) + 1;
			history_temp.push( history_all[i] );
		}
	}

	// Prepare data from temporary array
	for ( let i=Number(page); i<( Number(history_pagination) + Number(page) ); i++)
	{
		if ( history_temp[i] ) {
			history.push( history_temp[i] );
		}
	}
}
//===========================================================

//==] PAGINATION - NEXT PAGE [===============================
function Paginate_next() {
	history_page = ( Number(history_page) + Number(history_pagination) );
	Paginate(history_page);
}
//===========================================================

//==] PAGINATION - PREVIOUS PAGE [===========================
function Paginate_previous() {
	history_page = ( Number(history_page) - Number(history_pagination) );
	Paginate(history_page);
}
//===========================================================
</script>

{#if $location == "/history"}
	<div class="container mt-3">
		{#if $locked == true}
			<div in:fade class="alert alert-info">
				<SvgIcon type="mdi" path="{mdiLoading}" class="icon-spinner"></SvgIcon> Proszę czekać, trwa ładowanie danych...
			</div>
		{/if}
		{#if $locked == false}
			<div class="card shadow">
				<div in:fade class="card-header">
					<div class="row align-items-center">
						<div class="col d-flex align-items-center text-truncate fs-5">
							<SvgIcon type="mdi" path="{mdiHistory}" size="{32}" class="me-1"></SvgIcon>
							Historia wygenerowanych kodów kreskowych:
						</div>
						<div class="col-auto">
							{#if history.length > 1}
								<button class="btn btn-{$theme}" type="button" title="Skasuj całą historię wydruków"
								data-bs-toggle="modal" data-bs-target="#modal_history_delete_all"
								disabled="{$locked}"
								>
								<SvgIcon type="mdi" path={mdiDelete}></SvgIcon>
								</button>
							{/if}
						</div>
					</div>
				</div>
				{#if history.length == 0}
					<div class="card-body">
						Brak wygenerowanych kodów kreskowych w historii...
					</div>
				{:else}
					<ul class="list-group list-group-flush">
						{#each history as h, i}
							<li class="list-group-item list-group-item-action">
								<div class="row align-items-center">
									<div class="col-auto">{(i+1+Number(history_page))}.</div>
									<div class="col">
										<div class="fw-bold">{h['generated_date']} - {h['group_name']}</div>
										<div class="text-muted">
											<span class="me-1">Liczba stron: {h['pages']},</span>
											<span class="me-1">Liczba kodów: {(h['pages']*27)},</span>
											<span class="me-1">Wygenerowane numery: {h['number_from']} - {h['number_to']}</span>
										</div>
									</div>
									<div class="col-auto">

										<div in:fade class="dropdown m-auto">
											<button class="btn btn-lg btn-{$theme} d-flex align-items-center p-1" type="button" data-bs-toggle="dropdown" title="Akcja"
											id="dropdown_history_{i}"
											disabled="{$locked}"
											>
											<SvgIcon type="mdi" path={mdiDotsVertical}></SvgIcon>
											</button>
											<ul class="dropdown-menu dropdown-menu-end shadow" aria-labelledby="dropdown_history_{i}">
												<li>
													<button class="dropdown-item d-flex align-item-center" type="button"
													data-bs-toggle="modal"
													data-bs-target="#modal_history_generate"
													on:click={()=>{history_gen=h}}
													title="Wygeneruj kody kreskowe jeszcze raz">
													<SvgIcon type="mdi" path="{mdiBarcode}" class="me-1"></SvgIcon> Generuj ponownie
													</button>
												</li>

												<li>
													<button class="dropdown-item d-flex align-item-center" type="button"
													data-bs-toggle="modal"
													data-bs-target="#modal_history_delete"
													on:click={()=>{id=h['id']; group_name=h['group_name']}}
													title="Usuń pozycję z historii">
													<SvgIcon type="mdi" path="{mdiDelete}" class="me-1"></SvgIcon> Usuń
													</button>
												</li>
											</ul>
									</div>
								</div>
							</li>
						{/each}
					</ul>

					{#if ( Number(history_pagination) ) < Number(history_max) }
						<div class="card-footer">
							<div class="row justify-content-center">
								<div class="col text-end">
									{#if Number(history_page) > 0}
										<button in:scale out:scale class="btn btn-secondary align-items-center" title="Poprzednia strona" on:click='{Paginate_previous}'
											disabled="{$locked}">
											<SvgIcon type="mdi" path="{mdiChevronLeftCircle}"></SvgIcon>
										</button>
									{/if}
								</div>
								<div class="col">
									{#if ( Number(history_page) + Number(history_pagination) ) < Number(history_max) }
										<button in:scale out:scale class="btn btn-secondary align-items-center" title="Następna strona" on:click='{Paginate_next}'
											disabled="{$locked}">
											<SvgIcon type="mdi" path="{mdiChevronRightCircle}"></SvgIcon>
										</button>
									{/if}
								</div>
							</div>
						</div>
					{/if}

				{/if}
			</div>
		{/if}
	</div>
	<History_delete Callback={History} bind:id={id} bind:group_name={group_name} />
	<History_delete_all Callback={History} />
	<History_generate bind:history={history_gen} bind:library_data={library_data} />
{/if}

