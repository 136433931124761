<script>
//==] IMPORT MODULES [=======================================
import { getContext } from 'svelte';
import Router, {location} from 'svelte-spa-router';
import Codes from './frontend_codes.svelte';
//===========================================================

//==] IMPORT STORE VARIABLES [===============================
let header_text = getContext('header_text');
//===========================================================

//==] SET MENU HEADER TEXT AND LOAD DATA [===================
if ( $location == '/') {
	$header_text = "Strona główna";
}
//===========================================================
</script>

{#if $location == "/"}
	<div class="container mt-3">
		<Codes />
	</div>
{/if}

