<script>
//==] IMPORT MODULES [=======================================
import { getContext } from 'svelte';
import { fade,scale } from 'svelte/transition';
import Router, {push} from 'svelte-spa-router';
import SvgIcon from '@jamescoyle/svelte-icon';
import { mdiLoading, mdiBarcodeScan, mdiFormatListGroup, mdiHistory } from '@mdi/js';
import axios from 'axios';

import Codes_generate from './frontend_codes_generate.svelte';
//===========================================================

//==] IMPORT STORE VARIABLES [===============================
let state = getContext('state');
let locked = getContext('locked');
//===========================================================

//==] DEFINE LOCAL VARIABLES [===============================
let groups = [];
let group = "";
let library_data = "";
//===========================================================

//==] GET DATA [=============================================
function Groups() {
	$locked = true;
	axios.post('assets/backend_groups.php', {
		action: 'groups',
	}).then( (response) => {
		if ( response.data.output == "SUCCESS" )
		{
			$locked = false;
			groups = response.data.groups;
			library_data = response.data.library;
			for ( let i=0; i<groups.length; i++ ) {
				groups[i]['number'] = (Number(groups[i]['number']) + 1).toString();
				while ( groups[i]['number'].length < 9 ) {
					groups[i]['number'] = "0"+groups[i]['number'];
				}
			}
		}
		else {
			$state = "error";
		}
	}).catch( (error) => {
		console.log( error );
		$state = "error";
	});
}

Groups();
//===========================================================
</script>

<div class="container mt-3">
	{#if $locked == true}
		<div in:fade class="alert alert-info">
			<SvgIcon type="mdi" path="{mdiLoading}" class="icon-spinner"></SvgIcon> Proszę czekać, trwa ładowanie danych...
		</div>
	{/if}
	{#if $locked == false}
		<div class="card shadow">
			<div in:fade class="card-header d-flex align-items-center text-truncate fs-5">
				<SvgIcon type="mdi" path="{mdiBarcodeScan}" size="{32}" class="me-1"></SvgIcon>
				Generowanie kodów kreskowych:
			</div>
			{#if groups.length == 0}
				<div class="card-body">
					Brak zdefiniowanych grup kodów kreskowych...
				</div>
			{:else}
				<ul class="list-group list-group-flush">
					{#each groups as g, i}
						<li class="list-group-item list-group-item-action">
							<div class="row align-items-center">
								<div class="col-auto">{(i+1)}.</div>
								<div class="col">
									<span class="fw-bold">{g['name']}</span><br>
									<span class="text-muted">Następny numer kodu kreskowego:
										{#if g['prefix'] != ""}
											{g['prefix']}-
										{/if}
										{g['pool']}{g['number']}
									</span>
								</div>
								<div class="col-auto">
									<div class="btn-group">
										<button class="btn btn-secondary d-flex align-items-center" type="button" title="Historia wydruków dla grupy: {g['name']}"
										on:click={push("/history/:"+g['id'])}
										>
											<SvgIcon type="mdi" path={mdiHistory}></SvgIcon>
										</button>

										<button class="btn btn-primary d-flex align-items-center" type="button" title="Generuj kody kreskowe dla grupy: {g['name']}"
										disabled="{$locked}"
										data-bs-toggle="modal" data-bs-target="#modal_codes_generate"
										on:click={()=>{group=g;}}
										>
											<SvgIcon type="mdi" path={mdiBarcodeScan} class="me-1"></SvgIcon>
											Generuj kody
										</button>
									</div>
								</div>
							</div>
						</li>
					{/each}
				</ul>
			{/if}
		</div>
	{/if}
</div>
<Codes_generate Callback={Groups} bind:group={group} bind:library_data={library_data} />
