<script>
//==] IMPORT MODULES [=======================================
import { fade } from 'svelte/transition';
import SvgIcon from '@jamescoyle/svelte-icon';
import { mdiInformation,} from '@mdi/js';
import {app_name,app_version,app_copyright,app_description} from '../variables.js';
//===========================================================
</script>

<div class="modal fade" out:fade id="modal_about" tabindex="-1">
	<div class="modal-dialog modal-md">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title d-flex align-items-center">
					<SvgIcon type="mdi" path="{mdiInformation}" size={32} class="me-1"></SvgIcon>
					O programie:
				</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" title="Zamknij okno"></button>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="col-12 mb-3 text-center">
						<img src="assets/logo.svg" width="128px" height="128px" alt="Logo aplikacji" />
					</div>
					<div class="col-12 mb-3 text-center fw-bold">
						{app_name}
					</div>
					<div class="col-12 mb-3 text-center">
						{app_version}
					</div>
					<div class="col-12 mb-3 text-center">
						{app_description}
					</div>
					<div class="col-12 mb-3 text-center">
						Copyright &copy; 2017 - 2024 Rafał Wojciechowski
					</div>
					<div class="col-12 mb-3 text-center">
						Licencja dla: <strong>{app_copyright}</strong>
					</div>
				</div>
			</div>
			<div class="modal-footer text-end">
				<button class="btn btn-secondary" type="button" title="Zamknij okno" data-bs-dismiss="modal">
					Zamknij
				</button>
			</div>
		</div>
	</div>
</div>
