<script>
//==] IMPORT MODULES [=======================================
import { getContext } from 'svelte';
import { fade } from 'svelte/transition';
import axios from 'axios';
import SvgIcon from '@jamescoyle/svelte-icon';
import { mdiLoading,mdiBookInformationVariant,mdiContentSave } from '@mdi/js';
import * as bootstrap from 'bootstrap';

export let Settings_callback = () => {};
export let rotate_logs;

let state = getContext('state');
let notification_show = getContext('notification_show');
let notification_type = getContext('notification_type');
let notification_text = getContext('notification_text');
let locked = getContext('locked');
//===========================================================

//==] SAVE LOGS SETTINGS [===================================
function Save() {
//--] LOCK INTERFACE [---------------------------------------
$locked = true;
//-----------------------------------------------------------

//--] DELETE LOGS AND REFRESH INTERFACE [--------------------
axios.post('assets/backend_settings.php', {
	action: 'settings4',
	rotate_logs: rotate_logs
}).then( (response) => {
	if ( response.data.output == "SUCCESS" )
	{
		const modal_hide = document.querySelector('#modal_logs_settings');
		const modal = bootstrap.Modal.getInstance(modal_hide);
		modal.hide();
		$locked = false;

		$notification_type = "success";
		$notification_text = "Ustawienie rotowania logów na max <strong>"+rotate_logs+"</strong> pozycji.";
		$notification_show = true;

		Settings_callback();
	}
	else {
		$state = "error";
	}
}).catch( (error) => {
	console.log( error );
	$state = "error";
});
//-----------------------------------------------------------
}
//===========================================================
</script>

<div class="modal fade" out:fade id="modal_logs_settings" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
	<div class="modal-dialog modal-md">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title align-items-center">
					<SvgIcon type="mdi" path="{mdiBookInformationVariant}" size={32} class="me-1"></SvgIcon>
					Ustawienia logów:
				</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" title="Zamknij okno" disabled="{$locked}"></button>
			</div>
			<div class="modal-body">
				<p>Maksymalna ilość przechowywanych logów:</p>
				<select class="form-select" bind:value='{rotate_logs}' disabled="{$locked}">
				<option value="100">100 pozycji</option>
				<option value="500">500 pozycji</option>
				<option value="1000">1000 pozycji</option>
				<option value="5000">5000 pozycji</option>
				<option value="10000">10000 pozycji</option>
				</select>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-bs-dismiss="modal" title="Anuluj" disabled="{$locked}">Anuluj</button>
				<button type="button" class="btn btn-primary d-flex align-items-center" title="Zapisz ustawienia logów aplikacji" disabled="{$locked}" on:click={Save}>
					{#if $locked == false}
						<SvgIcon type="mdi" path="{mdiContentSave}" class="me-1"></SvgIcon>
					{/if}
					{#if $locked == true}
						<SvgIcon type="mdi" path="{mdiLoading}" class="me-1 icon-spinner"></SvgIcon>
					{/if}
					Zapisz
				</button>
			</div>
		</div>
	</div>
</div>

