<script>
//==] IMPORT MODULES [=======================================
import { getContext } from 'svelte';
import { fade } from 'svelte/transition';
import { push } from 'svelte-spa-router';
import axios from 'axios';
import SvgIcon from '@jamescoyle/svelte-icon';
import { mdiLoading,mdiBookInformationVariant,mdiDeleteCircle } from '@mdi/js';
import * as bootstrap from 'bootstrap';

export let Settings_callback = () => {};

let state = getContext('state');
let notification_show = getContext('notification_show');
let notification_type = getContext('notification_type');
let notification_text = getContext('notification_text');
let locked = getContext('locked');
//===========================================================

//==] DELETE LOGS [==========================================
function Delete() {
//--] LOCK INTERFACE [---------------------------------------
$locked = true;
//-----------------------------------------------------------

//--] DELETE LOGS AND REFRESH INTERFACE [--------------------
axios.post('assets/backend_settings.php', {
	action: 'settings3',
}).then( (response) => {
	if ( response.data.output == "SUCCESS" )
	{
		const modal_hide = document.querySelector('#modal_logs_delete');
		const modal = bootstrap.Modal.getInstance(modal_hide);
		modal.hide();
		$locked = false;

		$notification_type = "success";
		$notification_text = "Logi aplikacji zostały skasowane.";
		$notification_show = true;

		Settings_callback();
	}
	else {
		$state = "error";
	}
}).catch( (error) => {
	console.log( error );
	$state = "error";
});
//-----------------------------------------------------------
}
//===========================================================
</script>

<div class="modal fade" out:fade id="modal_logs_delete" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
	<div class="modal-dialog modal-md">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title align-items-center">
					<SvgIcon type="mdi" path="{mdiBookInformationVariant}" size={32} class="me-1"></SvgIcon>
					Kasowanie logów:
				</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" title="Zamknij okno" disabled="{$locked}"></button>
			</div>
			<div class="modal-body">
				<p>Czy na pewno chcesz skasować logi aplikacji?</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-bs-dismiss="modal" title="Anuluj" disabled="{$locked}">Anuluj</button>
				<button type="button" class="btn btn-danger d-flex align-items-center" title="Skasuj logi aplikacji" disabled="{$locked}" on:click={Delete}>
					{#if $locked == false}
						<SvgIcon type="mdi" path="{mdiDeleteCircle}" class="me-1"></SvgIcon>
					{/if}
					{#if $locked == true}
						<SvgIcon type="mdi" path="{mdiLoading}" class="me-1 icon-spinner"></SvgIcon>
					{/if}

					Usuń
				</button>
			</div>
		</div>
	</div>
</div>

