<script>
//==] IMPORT MODULES [=======================================
import { getContext } from 'svelte';
import Router, {location} from 'svelte-spa-router';
import SvgIcon from '@jamescoyle/svelte-icon';
import { mdiAlertCircle } from '@mdi/js';

//==] DEFINE STORE VARIABLES [===============================
let header_text = getContext('header_text');
//===========================================================

//==] SET MENU HEADER TEXT AND LOAD DATA [===================
$: { 
	$location;
	$header_text = "Strony nie odnaleziono";
}
//===========================================================

</script>

<div class="container mt-3">
	<div class="alert alert-danger m-4">
		<h3>404</h3>
		<SvgIcon type="mdi" path={mdiAlertCircle}></SvgIcon> Podanej strony nie odnaleziono...
	</div>
</div>
