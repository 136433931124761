<script>
//==] IMPORT MODULES [=======================================
import SvgIcon from '@jamescoyle/svelte-icon';
import { mdiLoading } from '@mdi/js';
//===========================================================
</script>

<div class="container mt-3">
	<div class="alert alert-info m-4">
		<SvgIcon type="mdi" path={mdiLoading} class="icon-spinner"></SvgIcon> Proszę czekać, trwa wczytywanie danych...
	</div>
</div>
