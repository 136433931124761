<script>
//==] IMPORT MODULES [=======================================
import { getContext } from 'svelte';
import { fade } from 'svelte/transition';
import { push } from 'svelte-spa-router';
import axios from 'axios';
import SvgIcon from '@jamescoyle/svelte-icon';
import { mdiLoading,mdiDatabaseSync,mdiReload } from '@mdi/js';
import * as bootstrap from 'bootstrap';

export let Settings_callback = () => {};
export let backup_file;

let state = getContext('state');
let notification_show = getContext('notification_show');
let notification_type = getContext('notification_type');
let notification_text = getContext('notification_text');
let locked = getContext('locked');
//===========================================================

//==] RESTORE DATABASE FROM BACKUP [=========================
function Restore() {
//--] LOCK INTERFACE [---------------------------------------
$locked = true;
//-----------------------------------------------------------

//--] REMOVE BACKUP AND REFRESH INTERFACE [------------------
axios.post('assets/backend_settings.php', {
	action: 'settings7',
	filename: backup_file
}).then( (response) => {
	console.log(response.data.output);
	if ( response.data.output == "SUCCESS" )
	{
		const modal_hide = document.querySelector('#modal_backup_restore');
		const modal = bootstrap.Modal.getInstance(modal_hide);
		modal.hide();
		$locked = false;

		$notification_type = "success";
		$notification_text = "Baza danych została przywrócona z pliku kopii zapasowej: <strong>"+backup_file+"</strong>.";
		$notification_show = true;

		Settings_callback();

		setTimeout( () => {
			location.reload();
		},500);
	}
	else {
		$state = "error";
	}
}).catch( (error) => {
	console.log( error );
	$state = "error";
});
//-----------------------------------------------------------
}
//===========================================================
</script>

<div class="modal fade" out:fade id="modal_backup_restore" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title align-items-center">
					<SvgIcon type="mdi" path="{mdiDatabaseSync}" size={32} class="me-1"></SvgIcon>
					Przywracanie bazy danych:
				</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" title="Zamknij okno" disabled="{$locked}"></button>
			</div>
			<div class="modal-body">
				<p>Czy na pewno chcesz przywrócić bazę danych z pliku: <strong>{backup_file}</strong>?</p>
				<p>Pamiętaj, że po przywróceniu bazy danych ze starej kopii zapasowej możesz utracić ważne dane. Najprawdopodobniej będziesz musiał także 
				ponownie zalogować się do aplikacji.
				</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-bs-dismiss="modal" title="Anuluj" disabled="{$locked}">Anuluj</button>
				<button type="button" class="btn btn-primary d-flex align-items-center" title="Przywróć bazę danych z pliku kopii zapasowej" disabled="{$locked}"
				on:click={Restore}>
					{#if $locked == false}
						<SvgIcon type="mdi" path="{mdiReload}" class="me-1"></SvgIcon>
					{/if}
					{#if $locked == true}
						<SvgIcon type="mdi" path="{mdiLoading}" class="me-1 icon-spinner"></SvgIcon>
					{/if}

					Przywróć
				</button>
			</div>
		</div>
	</div>
</div>

