<script>
//==] IMPORT MODULES [=======================================
import { getContext } from 'svelte';
import { fade } from 'svelte/transition';
import SvgIcon from '@jamescoyle/svelte-icon';
import { mdiDelete, mdiClose, mdiLoading } from '@mdi/js';
import * as bootstrap from 'bootstrap';
import axios from 'axios';
//===========================================================

//==] IMPORT VARIABLES [=====================================
let locked = getContext('locked');
let state = getContext('state');
let notification_type = getContext('notification_type');
let notification_text = getContext('notification_text');
let notification_show = getContext('notification_show');

export let group_name;
export let id;
export let Callback = () => {};
//===========================================================

//==] DELETE POSITION FROM HISTORY [=========================
function Delete() {
//--] LOCK INTERFACE [---------------------------------------
$locked = true;
//-----------------------------------------------------------

//--] DELETE GROUP AND REFRESH LIST [------------------------
axios.post('assets/backend_history.php', {
	action: 'history2',
	id: id
}).then( (response) => {
	if ( response.data.output == "SUCCESS" )
	{
		const modal_hide = document.querySelector('#modal_history_delete');
		const modal = bootstrap.Modal.getInstance(modal_hide);
		modal.hide();

		$locked = false;
		$notification_type = "success";
		$notification_text = "Pozycja w historii kodów kreskowych dla grupy <strong>"+group_name+"</strong> została usunięta.";
		$notification_show = true;

		Callback(); 
	}
	else {
		$state = "error";
	}
}).catch( (error) => {
	console.log( error );
	$state = "error";
});
//-----------------------------------------------------------
}
//===========================================================
</script>

<div class="modal fade" out:fade id="modal_history_delete" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
	<div class="modal-dialog modal-md">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title d-flex align-items-center">
					<SvgIcon type="mdi" path="{mdiDelete}" size={32} class="me-1"></SvgIcon>
					Usuwanie pozycji z historii:
				</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" title="Zamknij okno" disabled="{$locked}"></button>
			</div>
			<div class="modal-body">
				<p>Czy na pewno chcesz usunąć pozycję dla grupy: <strong>{group_name}</strong>?</p>
			</div>
			<div class="modal-footer text-end">
				<button class="btn btn-secondary d-flex align-items-center" type="button" title="Anuluj"
				disabled="{$locked}"
				data-bs-dismiss="modal">
					<SvgIcon type="mdi" path={mdiClose} class="me-1"></SvgIcon>
					Anuluj
				</button>
				<button class="btn btn-danger d-flex align-items-center" type="button" title="Usuń pozycję"
				disabled="{$locked}"
				on:click={Delete}
				>
					{#if $locked == false}
						<SvgIcon type="mdi" path={mdiDelete} class="me-1"></SvgIcon>
					{/if}
					{#if $locked == true}
						<SvgIcon type="mdi" path={mdiLoading} class="me-1 icon-spinner"></SvgIcon>
					{/if}

					Usuń
				</button>

			</div>
		</div>
	</div>
</div>
