<script>
//==] IMPORT MODULES [=======================================
import { getContext } from 'svelte';
import {location} from 'svelte-spa-router';
import { fade } from 'svelte/transition';
import SvgIcon from '@jamescoyle/svelte-icon';
import { mdiPower, mdiLogout, mdiLoading } from '@mdi/js';
import axios from 'axios';

let header_text = getContext('header_text');
let state = getContext('state');
let notification_show = getContext('notification_show');
let locked = getContext('locked');
//===========================================================

//==] SET MENU HEADER TEXT [=================================
if ( $location == '/logout') {
	$header_text = "Wylogowanie"
}
//===========================================================

//==] LOGOUT [===============================================
function Logout() {
	$locked = true;
	$notification_show = false;
	axios.post('assets/backend_login.php', {
		action: 'logout'
	}).then( (response) => {
		if ( response.data.output == "SUCCESS" )
		{
			$locked = false;
			$state = "login";
		}
		else {
			$state = "error";
		}
	}).catch( (error) => {
		console.log( error );
		$state = "error";
	});
}
//===========================================================
</script>

{#if $location == "/logout" && $state == "logged_in"}
	<div in:fade class="container mt-3">
		<div class="alert alert-info">
			<SvgIcon type="mdi" path="{mdiPower}"></SvgIcon> Czy na pewno chcesz się wylogować?
		</div>
		<hr>
		<button class="btn btn-primary ms-auto me-0 d-inline-block" title="Wyloguj się" disabled="{$locked}" on:click={Logout}>
			{#if $locked == false}
				<SvgIcon type="mdi" path="{mdiLogout}"></SvgIcon>
			{/if}
			{#if $locked == true}
				<SvgIcon type="mdi" path="{mdiLoading}" class="icon-spinner"></SvgIcon>
			{/if}
			Wyloguj się
		</button>
	</div>
{/if}
