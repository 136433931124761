<script>
//==] IMPORT MODULES [=======================================
import { getContext } from 'svelte';
import { fade,scale } from 'svelte/transition';
import SvgIcon from '@jamescoyle/svelte-icon';
import { mdiLoading, mdiBarcode, mdiClose } from '@mdi/js';
import axios from 'axios';
import * as bootstrap from 'bootstrap';
import {app_copyright} from '../variables.js';

import html2pdf from 'html2pdf.js';
import JsBarcode from "jsbarcode";
//===========================================================

//==] IMPORT STORE VARIABLES [===============================
let state = getContext('state');
let locked = getContext('locked');
let notification_type = getContext('notification_type');
let notification_text = getContext('notification_text');
let notification_show = getContext('notification_show');
//===========================================================

//==] IMPORT AND DEFINE VARIABLES [==========================
export let group;
export let library_data;
export let Callback = () => {};
let paper_type="special";
let pages="1";
let barcode = "";
//===========================================================

//==] GENERATE BARCODE [=====================================
function Barcode(num) {
var canvas = document.createElement("canvas");
	JsBarcode(canvas, num, {
		format: "CODE128",
		displayValue: false,
		width:6,
		height:200
	});
	return canvas.toDataURL("image/png");
}
//===========================================================

//==] GENERATE PDF DOCUMENT WITH BARCODES [==================
function Generate() {
//--] LOCK INTERFACE [---------------------------------------
$locked = true;
//-----------------------------------------------------------

//--] DEFINE VARIABLES [-------------------------------------
let pdf_name = "kody_kreskowe_"+group['name'].toLowerCase().replace(' ','_');
pdf_name = pdf_name+"_"+Number(group['number']);
let number = Number(group['number']);
let num = '';
//-----------------------------------------------------------

//--] DEFINE HTML DATA [-------------------------------------
let html = `
<html lang="pl">
<head>
<style>
table { border-collapse: collapse; 
margin-top:4mm;
}

* { color: #000000; }
td { font-size:10pt; text-align:center; padding:0; margin:0;
width:70mm; height:32mm;
`;
if ( paper_type == 'normal' ) {
	html += `border: 1px solid #000000;`;
} else {
	html == `border:none;`;
}
html += `
}
</style>
</head>
<body>
`;
for ( let i=0; i<Number(pages); i++ ) {
	html += `
	<table class="pagebreak">
	<tbody>
	`;
	for ( let j=0; j<9; j++ ) {
		html += `
		<tr>
		`;
		for ( let k=0; k<3; k++ ) {
			num = number.toString();
			while ( num.length < 9 ) {
				num = "0"+num;
			}
			num = group['pool'] + num;
			if ( group['prefix'] != "" ) {
				num = group['prefix'] + "-" + num;
			}

			barcode = Barcode(num);

			html += `
			<td>
				<div style="font-size:`+library_data['font_size']+`pt;">`+library_data['name']+`</div>
				<img src="`+barcode+`" style="width:65mm; height:15mm;">
				`+num+`
			</td>
			`;
			number = number + 1;
		}
	html += `
	</tr>
	`;
	}
html += `
</tbody>
</table>
`;
}
html += `
</body>
</html>
`;
//-----------------------------------------------------------

//--] DEFINE PDF [-------------------------------------------
pdf_name = pdf_name+"_"+(Number(number)-1);
console.log(pdf_name);
var options = {
	jsPDF: {
	format: 'a4',
	unit: 'mm',
	orientation: 'portrait'
},
	pagebreak: { after: '.pagebreak' },
	filename: pdf_name+'.pdf',
	html2canvas: { dpi: 192, letterRendering: true, useCORS: true, logging: true, allowTaint: true, scale: 3},
	margin: 0,
	image: {type: 'jpeg', quality: 1}
};

//html2pdf().set(options).from(html).toPdf().output('dataurlnewwindow',pdf_name+'.pdf');
//-----------------------------------------------------------

//--] UPDATE NEXT NUMBER, ADD HISTORY AND UNLOCK INTERFACE [-
axios.post('assets/backend_groups.php', {
	action: 'groups5',
	id: group['id'],
	pages: pages
}).then( (response) => {
	if ( response.data.output == "SUCCESS" )
	{
		$locked = false;
		$notification_type = "success";
		$notification_text = "Wygenerowanie kodów kreskowych dla grupy <strong>"+group['name']+"</strong>.";
		$notification_show = true;

		Callback();

		const modal_hide = document.querySelector('#modal_codes_generate');
		const modal = bootstrap.Modal.getInstance(modal_hide);
		modal.hide();

		setTimeout( ()=> {
			html2pdf().set(options).from(html).toPdf().save();
		},300);
	}
	else {
		$state = "error";
	}
}).catch( (error) => {
	console.log( error );
	$state = "error";
});
//-----------------------------------------------------------
}
//===========================================================
</script>

<div class="modal fade" out:fade id="modal_codes_generate" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
	<div class="modal-dialog modal-md">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title d-flex align-items-center">
					<SvgIcon type="mdi" path="{mdiBarcode}" size={32} class="me-1"></SvgIcon>
					Generowanie kodów kreskowych:
				</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" title="Zamknij okno" disabled="{$locked}"></button>
			</div>
			<div class="modal-body">
				<label class="form-label" for="pages">Liczba stron:</label>
				<select class="form-select mb-3" id="pages" bind:value={pages} disabled="{$locked}">
					<option value="1">1 strona (27 kodów)</option>
					<option value="2">2 strony (54 kodów)</option>
					<option value="3">3 strony (81 kodów)</option>
					<option value="4">4 strony (108 kodów)</option>
					<option value="5">5 stron (135 kodów)</option>
					<option value="6">6 stron (162 kody)</option>
					<option value="7">7 stron (189 kodów)</option>
					<option value="8">8 stron (216 kodów)</option>
					<option value="9">9 stron (243 kody)</option>
					<option value="10">10 stron (270 kodów)</option>
				</select>

				<label class="form-label" for="paper_type">Typ papieru:</label>
				<select class="form-select mb-3" id="paper_type" bind:value={paper_type} disabled="{$locked}">
					<option value="special">Papier naklejkowy (27 kodów na stronę)</option>
					<option value="normal">Zwykły papier do samodzielnego wycięcia</option>
				</select>
			</div>
			<div class="modal-footer text-end">
				<button class="btn btn-secondary d-flex align-items-center" type="button" title="Anuluj"
				disabled="{$locked}"
				data-bs-dismiss="modal">
					<SvgIcon type="mdi" path={mdiClose} class="me-1"></SvgIcon>
					Anuluj
				</button>
				<button class="btn btn-primary d-flex align-items-center" type="button" title="Generuj kody kreskowe"
				disabled="{$locked}"
				on:click={Generate}
				>
					{#if $locked == false}
						<SvgIcon type="mdi" path={mdiBarcode} class="me-1"></SvgIcon>
					{/if}
					{#if $locked == true}
						<SvgIcon type="mdi" path={mdiLoading} class="me-1 icon-spinner"></SvgIcon>
					{/if}
					Generuj
				</button>
			</div>
		</div>
	</div>
</div>
