<script>
//==] IMPORT MODULES [=======================================
import { scale } from 'svelte/transition';
import { getContext } from 'svelte';
import Router, {location, push} from 'svelte-spa-router';
import axios from 'axios';
import SvgIcon from '@jamescoyle/svelte-icon';
import { mdiLock, mdiLockOpenVariant, mdiLoading, mdiAlertCircle } from '@mdi/js';
let state = getContext('state');
let notification_show = getContext('notification_show');
let locked = getContext('locked');
//===========================================================

//==] DEFINE VARIABLES [=====================================
let password = '';
let login = false;
let error = false;
let button_disabled = true;
//===========================================================

//==] LOCK/UNLOCK LOGIN BUTTON [=============================
function Validate_login() {
	error = false;
	if( password.length > 2 ) {
		button_disabled = false;
	}
	else {
		button_disabled = true;
	}
}
//===========================================================

//==] LOGIN [================================================
function Login() {
//--] LOCK INTERFACE [---------------------------------------
login = true;
$locked = true;
button_disabled = true;
$notification_show = false;
//-----------------------------------------------------------

//--] LOGIN [------------------------------------------------
axios.post('assets/backend_login.php', {
	action: 'login2',
	password: password
}).then( (response) => {
	if ( response.data.output == "SUCCESS" )
	{
		$state = "logged_in";
		$locked = false;
		push('/');
	}
	else if ( response.data.output == "ERROR" )
	{
		password = '';
		error = true;
		login = false;
		$locked = false;
		button_disabled = false;
	}
	else {
		$state = "error";
	}

	}).catch( (error) => {
		console.log( error );
		$state = "error";
	});

//-----------------------------------------------------------
}
//===========================================================
</script>

<div class="container mt-3">
	<div class="row">
		<div class="col-12 mb-3 text-center">
			<img src="assets/logo.svg" width="128px" height="128px" alt="Logo aplikacji" />
		</div>
		<div class="col-xs-12 col-sm-1 col-md-2 col-lg-3"></div>
		<div class="col-xs-12 col-sm-10 col-md-8 col-lg-6">
			<div in:scale class="card shadow">
				<div class="card-header d-flex align-items-center bg-dark text-white">
					<SvgIcon type="mdi" path={mdiLock} class="me-1"></SvgIcon>
					<div class="fs-5 mt-auto mb-auto">Logowanie:</div>
				</div>
				<div class="card-body">
					<h5 class="card-title">Podaj hasło aby zalogować się do aplikacji:</h5>
					<form on:submit|preventDefault={Login}>
						<div class="input-group mt-3">
							<input type="password" class="form-control" name="app_password" id="app_password" required disabled="{$locked}" bind:value={password} on:input={Validate_login}>
							<button class="btn btn-primary" type="submit" title="Zaloguj się do aplikacji" disabled="{button_disabled}" on:submit={Login}>
								{#if login == false}
									<SvgIcon type="mdi" path={mdiLockOpenVariant}></SvgIcon>
								{/if}
								{#if login == true}
									<SvgIcon type="mdi" class="icon-spinner" path={mdiLoading}></SvgIcon>
								{/if}
								Zaloguj
							</button>
						</div>
					</form>
					{#if error == true}
						<div in:scale out:scale class="alert alert-danger mt-3">
						<SvgIcon class="mb-1" type="mdi" path={mdiAlertCircle}></SvgIcon> <strong>BŁĄD!</strong> Hasło jest niepoprawne.
						</div>
					{/if}
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-sm-1 col-md-2 col-lg-3"></div>
	</div>
</div>
